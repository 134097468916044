import "./App.css";
import Footer from "./Components/Footer/Footer";
import Hero from "./Components/HeroSection/Hero";
import Join from "./Components/Join/Join";
import Plan from "./Components/Plan/Plan";
import Program from "./Components/Program/Program";
import Reasons from "./Components/Reasons/Reasons";
import Testimonial from "./Components/Testimonial/Testimonial";
function App() {
  return (
    <div className="App">
      <Hero />
      <Program />
      <Reasons />
      <Plan />
      <Testimonial />
      <Join />
      <Footer />
    </div>
  );
}

export default App;
